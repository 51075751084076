$publicPath:'';

.import-pop {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  * {
    box-sizing: border-box;
  }
  .footer-btn {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
    /deep/.el-button {
      width: 88px;
    }
  }
  .content {
    padding: 24px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 632px;
    height: 468px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      background: url("~@/assets/img/close-btn.png") no-repeat center/cover;
      cursor: pointer;
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 74px;
      padding: 0 68px;
      .item {
        width: 188px;
        &:last-child .card .img {
          background: url("~@/assets/img/icon/upload-bg.png") no-repeat
            center/cover;
        }
        .title {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 24px;
        }
        .card {
          background-color: #f7f8f7;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 140px;
          cursor: pointer;
          user-select: none;
          .img {
            width: 48px;
            height: 48px;
            background: url("~@/assets/img/icon/down-bg.png") no-repeat
              center/cover;
          }
          .card-tit {
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular-, PingFangSC-Regular;
            font-weight: normal;
            color: #09a666;
          }
        }
      }
    }
    .import-pop-title {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }
}
