$publicPath:'';

@import "./../../assets/css/global-variable";
.g-box {
  overflow-x: hidden;
  height: 100%;
  background: #ffffff;
  width: 100%;
}

.home-top-header {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  left: 0;
  z-index: 99;
  height: 60px;
  .sub-logo-box {
    position: absolute;
    top: 25px;
    left: 165px;
    display: flex;
    align-items: center;
    height: 32px;
    .el-icon-close {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }
    .sub-logo-img {
      margin-left: 15px;
      max-width: 90px;
      min-width: 32px;
      height: 32px;
    }
  }
  .header-name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 20px;
  }
  .header-right {
    right: 60px;
    top: 10px;
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;

    span.right-item {
      font-size: 14px;
      color: #ffffff;
      margin-left: 30px;
      margin-right: 30px;
      cursor: pointer;
    }
    span.user-box {
      cursor: pointer;
      color: #ffffff;
      font-size: 14px;
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    .el-dropdown {
      color: #ffffff;
    }
  }
}

.home-box {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
}
.home-yyBtn {
  position: absolute;
  right: 90px;
  top: 275px;
  font-size: 14px;
  line-height: 40px;
  display: flex;
  z-index: 999;
  &-info {
    cursor: pointer;
    color: #fff;
    margin-right: 20px;
  }
  &-info:hover {
    text-decoration: underline;
  }
  &-btn {
    height: 40px;
    width: 153px;
    color: $fc-theme;
    border-radius: 20px;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
    box-shadow: inset 1px 1px 4px $fc-theme;
  }
}

.home-logo {
  background: url("~assets/img/logo.png") no-repeat;
  background-size: 100%;
  width: 91px;
  height: 32px;
  position: absolute;
  left: 60px;
  top: 25px;
}
// .home-tt {
//   background: url("~assets/img/home/tt.png") no-repeat;
//   background-size: 100%;
//   width: 218px;
//   height: 129px;
//   position: absolute;
//   left: 136px;
//   top: 140px;
// }
/*.home-bg{*/
/*    background: url('~assets/img/home/bg.jpg') no-repeat;*/
/*    background-size: 100%;*/
/*    height: 1080px;*/
/*    opacity: 0.5;*/
/*}*/

#myCanvas-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background: #070f3d url("~assets/img/home/home-bg.jpg") no-repeat 50% 0px;
  background-size: 1920px 1080px;
}

#myCanvas {
  display: block;
  position: relative;
  width: 1440px;
  height: 900px;
  position: absolute;

  .home-logo-1 {
    width: 128px;
    height: 83px;
    background: url("~assets/img/home/home-logo-1.png") no-repeat;
    background-size: cover;
    position: absolute;
    top: 80px;
    left: 740px;
  }
  .home-logo-2 {
    width: 462px;
    height: 79px;
    background: url("~assets/img/home/home-logo-2.png") no-repeat;
    position: absolute;
    top: 174px;
    left: 213px;
    background-size: cover;
  }

  .home-earth {
    width: 677px;
    height: 430px;
    background: url("~assets/img/home/home-earth.png") no-repeat;
    background-size: cover;
    position: absolute;
    top: 67px;
    left: 710px;
  }

  .home-light {
    width: 1440px;
    height: 900px;
    background: url("~assets/img/home/home-light.png") no-repeat;
    position: absolute;
    pointer-events: none;
    top: 0px;
    left: 0px;
    /*z-index: 10;*/
  }
}

.grid-box {
  width: 100%;
  position: absolute;
  top: 338px;
}
.item-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .item-box-icon {
    width: 86px;
    height: 86px;

    @for $i from 1 through 10 {
      &.icon#{$i} {
        background: url("~assets/img/home/icon#{$i}.png")
        center
        center
        no-repeat;
        background-size: auto 100%;
      }
    }
  }
  .item-box-title {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .item-box-desc {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }
}
.grid-box-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
}
.grid-box-item {
  position: relative;
  width: 214px;
  height: 214px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
  &:hover {
    background: #006cff;
    color: #ffffff;
    transform: scale(1.05);
    .item-box-icon {
      width: 86px;
      height: 86px;

      @for $i from 1 through 10 {
        &.icon#{$i} {
          background: url("~assets/img/home/icon#{$i}-active.png") no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .lock {
    display: none;
  }
  &.disabled {
    color: #c5c5c5;
    .item-box-icon {
      filter: grayscale(100%);
    }
    .lock {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      display: block;
      height: 0;
      border-top: 27px solid #67a8ff;
      border-right: 27px solid #67a8ff;
      border-left: 27px solid transparent;
      border-bottom: 27px solid transparent;
      img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -24px;
        right: -24px;
      }
    }
    &:hover {
      background: #747474;
    }
  }
}
.lang {
  border: solid 1px #747474;
  padding: 4px 8px;
  border-radius: 2px;
}
.lang-icon {
  display: inline-block;
  width: 16px;
  height: 11px;
  img {
    width: 100%;
  }
}
.avator {
  width: 32px;
  height: 32px;
  background: #b1e7ff;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
