@import '~element-ui/packages/theme-chalk/src/index';
@import 'theme';

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  font-size:0;
}
.clearfix { *zoom:1;}
.u-tp {
  //position: relative;
  //opacity: 0;
  //-webkit-animation-fill-mode: both;
  //animation-fill-mode: both;
  //transition: 10s;
}

/*浏览器滚动条*/
/*整体部分*/
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/*滑动轨道*/
::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(255, 255, 255, 0);
}

/*滑块*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #d1d1d1;
}

/*滑块效果*/
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: #d1d1d1;
}

.u-state-online{
  color:#41a556;
}
.u-state-offline{
  color:#f72f2f;
}


.primary-color{
  color: $fc-theme;
}

.danger-color{
  color: $fc-danger;
}

.warn-color{
  color: $fc-warning;
}

.el-button.is-circle{
  border: solid 1px transparent !important;

}

.m-page {
  display: flex;
  padding-top: 18px;
  margin-left: 20px;
  font-size: $fs-body;
  color: $fc-secondary;
  justify-content: space-between;
  align-items: center;
  &[data-m='list'] {
    position: fixed;
    bottom: 0px;
    right: 30px;
    z-index: 10;
    padding: 20px 30px;
    margin-left: 0px;
    width: calc(100% - 268px);
    height: 72px;
    box-sizing: border-box;
    background: #ffffff;
  }

  &[data-m='tree-list'] {
    position: fixed;
    bottom: 10px;
    right: 30px;
    z-index: 10;
    padding: 20px 30px;
    margin-left: 0px;
    width: calc(100% - 392px);
    height: 72px;
    box-sizing: border-box;
    background: #ffffff;
  }
  &[data-m='dialog-list'] {
    position: relative;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 0 20px;
    height: 72px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
  }
}

.m-form-inline {
  width: 100%;
  height: auto;
  .u-form-inline-mn {
    position: relative;
    overflow-x: auto;
    padding: 18px 12px;
    width: 100%;
    min-width: 916px;
    height: auto;
    min-height: 83px;
    font-size: 12px;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
  }
  .el-select, .el-date-editor--daterange.el-input__inner {
    width: 100%;
  }
  .m-form-inline-mn {
    .el-form-item {
      margin-bottom: 10px;

    }
  }
}

.m-list {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  &[data-m='list'] {
    padding-bottom: 80px;
  }
  &[data-m] .u-add {
    margin-bottom: 0px;
    padding: 0 20px;
    min-width: 110px;
    height: 32px;
  }
}

// .el-table th {
//   background-color: #FAFAFA !important;
// }

.m-form-inline-mn{
  padding: 40px 10px;
  width: 100%;
  // max-width: 1400px;
  // margin: 0 auto;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
}

.m-form-inline-mn[data-m='client'] {
  padding: 30px;
  width: 100%;
  max-width: 1400px;
  background: #ffffff;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 30px;
}

.m-form-inline-mn[data-m='add'] {
  overflow: auto;
  padding: 40px;
  width: 100%;
  height: calc(100% - 75px);
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  .el-select, .el-date-editor--daterange.el-input__inner, .el-date-editor.el-input {
    width: 100%;
  }
  .el-cascader {
    width: 100%;
  }
}

.g-form-extend-box{
  padding-left: 40px;
  padding-right: 40px;
}

.m-btn-group {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top: 2px solid $fc-bg-deep;
  .u-btn {
    width: 96px;
  }
  .s-cancel {
    background-color: $fc-bg-deep;
    border-color: $fc-bg-deep;
  }
}

.mt-15{
  margin-top: 15px;
}

.my-form{

  padding: 20px;
  .my-form-body{
    padding-top: 40px;


  }

  .my-form-body-empty2{
    height: 400px;
    text-align: center;
    font-size: 24px;
    color: #0677ff;

    .btn{
      margin-top:50px

    }

    p{
      margin-top:100px ;
    }
  }
  .my-form-body-empty{
    height: 400px;
    line-height: 400px;
    text-align: center;
    font-size: 24px;
    color: #0677ff;
  }

  .my-form-body-loading{
    height: 400px;
    line-height: 400px;
    text-align: center;
    font-size: 24px;
    color: #999999;
  }

  .my-form-header{
    margin-top: 0px;
    text-align: left;
    padding-bottom: 30px;
  }
  .my-form-footer{
    margin-top: 30px;
    text-align: center;
  }

}


.my-btns{
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  width:100%;
}


.g-main-box{
  // display: flex;
  background: #ffffff;


  .btns-box{
    display: flex;
    &-gutter{
      margin-left: 15px;
    }
  }

  .tree-box{
    float: left;
    box-sizing:border-box;
    border-right:1px solid#DCDCDC;
    width: 15%;
    padding: 20px;
    .tree-text{
      font-size: 16px;
      line-height: 32px;
    }
  }
  .right-box{
    float: left;
    width: 85%;
  }
  .action-tab{
    margin-left: 100px;
    margin-bottom: 20px;
  }
  .table-title{
    font-size: 14px;
    line-height: 3;
  }
}

.info-span{
  &:before{
    content: ':';
    position: relative;
    left: -10px;
  }
  color: #666666;

}
.info-dis-span{
  pointer-events: none;
}

.el-form-item__label{
  color: #000000;
}

.norow{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.tworow{
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
}

.el-tree--highlight-current
.el-tree-node.is-current
> .el-tree-node__content {
  background-color: #409eff;
  color: white;
}

.ml-15{
  margin-left: 15px;
}


.export-btn1{
  display: block;
  margin-top:5px;
  border-bottom:  solid 1px #f5f5f5;
  padding: 4px;
  cursor: pointer;
  &:last-child{
    border-bottom: none;

  }
}

.my-form-readonly{
  pointer-events: none;
  .el-upload-list{
    pointer-events: auto;

  }
}

.my-form-readonly .el-input__suffix-inner{
  pointer-events: inherit !important;
}

//g-role-add-box
.g-role-list-box{
  height: auto !important;
  min-height: 100% !important;
  position: relative !important;
}
.g-export-loading{
  position: absolute; left: 0; bottom: 0; top: 0; right: 0; z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.7);
}

.g-export-loading-process{
  background: #ffffff;
  padding: 20px 40px;
  border: solid 1px #333333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* info */
.img-upload {
  width: 100px;
  height: 100px;
  margin-left: 20px;
}
.g-role-add-box {
  position: relative;
  min-height: calc(100% - 64px) !important;
  color: $text-color-primary !important;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0px;
  margin-left: -30px;
  margin-right: -30px;
}
.text-color-primary{
  color: $text-color-primary !important;
}
.g-role-add-box-padding{
  margin-left: -30px;
  margin-right: -30px;
  background-color: transparent !important;
}


.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.m-tt-t {
  margin-left: 100px;
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.lcline {
  margin-left: 100px;
}
.u-tag {
  margin-right: 10px;
  cursor: pointer;
}
.m-select {
  border: 1px solid rgb(185, 180, 180);
  border-radius: 4px;
  padding: 0 6px;
  height: 300px;
  .u-select-hd {
    border-bottom: 1px solid rgb(185, 180, 180);
    .u-select-hd-icon {
      float: right;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .u-select-bt {
    overflow: auto;
    height: 260px;
  }
}
.s-h4 {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload {
  border: 1px dashed #409eff;
}
.avatar-uploader-icon {
  border: 1px solid #ddd;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-dialog{
  border-radius: 10px;
}

th.el-table__cell{
  padding:16px 0 !important
}
td.el-table__cell{
  padding:12px 0 !important
}
.el-table{
  color:#888888!important;
  thead{
    color:#888888!important;
  }
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #ffffff;
  color:$fc-theme;
}

.el-pager li{
  min-width:28px;
  border-radius: 4px;
}
.el-pager li.active{
  background:$fc-theme;
  color: #ffffff;
}

.required{
  color: #FF4D4F;
  padding-left: 2px;
  padding-right: 2px;
}

.el-table__body tr.hover-row > td.el-table__cell, .el-table__body tr.hover-row.current-row > td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped.current-row > td.el-table__cell{
  background-color: #e9f4f0;
}


.el-form-item__label {
  white-space: nowrap;
}



.aliplayer{
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  z-index: 10;
}

.aliplayer-box{
  width: 100%;
  height: 100%;
  background: #333333;
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.aliplayer-box-error{
  width: 188px;
  height: 188px;
  margin: 0 auto;
  background: url("~assets/img/tzh/video-error.png") no-repeat 50% 50%;
  background-size: cover;
}

.aliplayer-box span{
  color: #0aa665;
  cursor: pointer;
}
