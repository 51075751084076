$publicPath:'';

@import "./../../assets/css/global-variable";
$lg-bg: "../../assets/img/login/lg-bg.png";
$lg-icon-account: "../../assets/img/login/new-icon-account.png";
$lg-icon-password: "../../assets/img/login/new-icon-password.png";
$lg-icon-phone: "../../assets/img/login/new-icon-phone.png";
.u-reg-text {
    margin-top: 19px;
    font-size: 16px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #000000a6;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
}
input::placeholder {
    font-size: 16px;
    line-height: 40px;
}
#myCanvas-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background: linear-gradient(-45deg, #202f69, #1b1c4c);
}

.home-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background: url("~assets/img/login/login-repeat.png") repeat;
}
#myCanvas {
    display: block;
    position: relative;
    width: 1440px;
    height: 900px;
    position: absolute;
    background-size: 1440px;
    .login-logo {
        position: absolute;
        top: 55px;
        left: 0px;
        width: 129px;
        height: 46px;
        background: url("~assets/img/login/login-logo.png") no-repeat;
        background-size: cover;
    }

    .co2e-box {
        position: absolute;
        top: 155px;
        left: 80px;
        width: 536px;
        height: 584px;
        background: url("~assets/img/login/co2e.png") no-repeat;
        background-size: cover;
    }
}

.g-login-box {
    * {
        box-sizing: border-box;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 1440px;
    height: 900px;
    .c {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        .mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 30;
        }
        /*.bg {*/
        /*  width: 100%;*/
        /*  height: 100%;*/
        /*  position: absolute;*/
        /*  top: 0;*/
        /*    center/ 100% 100%;*/
        /*}*/
        .header {
            width: 100%;
            max-width: 1920px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: url($publicPath + "/bg/header.png") no-repeat;
            background-size: 100%;
            z-index: 99;
        }
        .header-title {
            text-align: center;
            line-height: 50px;
            font-size: 24px;
        }
    }
    .u-top-logo {
        width: 156px;
        height: 26px;
        position: absolute;
        left: 30px;
        top: 20px;
    }
    .u-center-logo {
        width: 430px;
        height: 44px;
        position: absolute;
        left: 25%;
        top: 50%;
        transform: translate(-50%);
    }
    .u-nav {
        width: 330px;
        height: 30px;
        position: absolute;
        top: 30px;
        right: 30px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
    }

    .u-vcode {
        cursor: pointer;
        display: inline-block;
        width: 90px;
        height: 40px;
        background: #d8ecfe;
        border-radius: 4px;
        overflow: hidden;
        right: -100px;
        line-height: 40px;
        position: absolute;
        img {
            width: 130px;
            height: 40px;
        }
    }
    .u-box {
        box-sizing: border-box;
        position: absolute;
        left: 60%;
        top: 50%;
        padding: 30px 40px 40px 40px;
        width: 480px;
        min-height: 410px;
        background: #ffffff;
        box-shadow: 0px 10px 30px 1px rgba(0, 0, 0, 0.03);
        border-radius: 16px 16px 16px 16px;
        box-sizing: border-box;
        z-index: 99;
        transform: translateY(-50%);
        /deep/.el-tabs {
            .el-tabs__content {
                padding-top: 30px;
            }
            .el-tabs__nav-wrap::after {
                display: none;
            }
            .el-tabs__item {
                font-size: 20px;
                font-family: PingFangSC-Medium-, PingFangSC-Medium;
                font-weight: 400;
                color: #000000 !important;
                &.is-active {
                    color: #0483ef !important;
                }
            }
        }
        /deep/.el-radio-group {
            padding-left: 12px;
            margin-bottom: 24px;
        }
        .u-it {
            position: relative;
            margin-bottom: 20px;
            height: 40px;
            line-height: 40px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #d9d9d9;
            input {
                font-size: 18px;
            }
            &:after {
                content: "";
                width: 20px;
                height: 20px;
                position: absolute;
                top: 10px;
                left: 10px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            &.u-account {
                &:after {
                    background-image: url($lg-icon-account);
                }
            }

            &.u-vcode-box {
                display: flex;
                justify-content: space-between;
                margin-top: 1px;
                width: 200px;
                .u-it-in {
                    margin-left: 12px;
                }
            }
            &.u-password {
                display: flex;
                justify-content: space-between;
                margin-top: 1px;
                &.u-code {
                    margin-bottom: 39px;
                }
                &:after {
                    background-image: url($lg-icon-password);
                }
                &.u-phoneCode {
                    margin-top: 43px;
                }
                .u-ver-btn {
                    position: relative;
                    &[disabled="disabled"] {
                        opacity: 0.8;
                        background-color: #ffffff;
                        height: 32px;
                        color: #0483efff;
                        height: 32px;
                    }
                    &::before {
                        top: 7px;
                        position: absolute;
                        left: 0px;
                        content: "";
                        height: 25px;
                        width: 1px;
                        background-color: #d9d9d9;
                    }
                    font-size: 16px;
                    user-select: none;
                    height: 40px;
                    font-size: 16px;
                    color: #0483efff;
                    text-align: center;
                    width: 111px;
                    line-height: 40px;
                    /* background: #ffffff; */
                    cursor: pointer;
                }
            }
            &.u-phone {
                &:after {
                    background-image: url($lg-icon-phone);
                }
            }
            .u-it-in {
                width: calc(100% - 36px);
                flex: 1;
                text-indent: 8px;
                height: 100%;
                margin-left: 36px;
                background-color: transparent;
                outline: none;
                box-shadow: none;
                border: none;
            }
            input.u-it-in::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.25);
            }
            .u-ver {
                margin-right: 10px;
                flex: 2;
                .u-it-in {
                    width: 180px;
                }
            }
            .u-tips {
                position: absolute;
                top: 30px;
                left: 0;
                color: $fc-danger;
            }
        }
        .u-remenber {
            .el-checkbox {
                color: #333333;
            }
        }
        .u-operating {
            margin-left: 12px;
            display: flex;
            margin-bottom: 20px;
            justify-content: space-between;
            font-size: $fs-body;
            color: #333333;
            .u-retrieve {
                cursor: pointer;
                color: #0483efff;
            }
        }
        .u-btn {
            width: 100%;
        }
    }
}
