/* 改变主题色变量 */
@import './global-variable.scss';
$--lg: 1440px;
$--color-primary: $fc-theme;

/* 改变 icon 字体路径变量，必需 */
$--font-path: '~element-ui/lib/theme-chalk/fonts';

.el-table{
  background-color: transparent!important;
  border: none;
  &::before {
    // display: none;
  }
}
.el-table th {
  // background-color: #081f4b !important;
  background-color:transparent !important;
  color: $text-color-white!important;
}
.el-table tr {
  background-color:transparent !important;
  color: $text-color-primary !important;
}
.el-table
.el-table tr:hover td,.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color:  #083169 !important;
  color: $text-color-primary !important;
}

.no_hover_table{
  .el-table
  .el-table tr:hover td,.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color:  transparent !important;
    color: inherit !important;
  }
}
.el-table th.el-table__cell.is-leaf, .el-table td.el-table__cell{
  border-bottom-color:rgba(255,255,255,0.12) !important;
}
.el-table--border .el-table__cell{
  border-bottom-color:transparent !important;
}
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background-color: $panel-bg-primary!important;
}
.el-table__body-wrapper{
  // background-color: #1a234a!important;
}
.el-table--group, .el-table--border{
  border-color: rgba(255,255,255,0.25)!important;
  &:after, &:before{
    background-color: rgba(255,255,255,0.25)!important;
  }
}
.el-pagination .btn-prev, .el-pagination .btn-next{
  background-color: $panel-bg-primary!important;
  color: $text-color-primary!important;
}
.el-pager li:not(.active){
  background-color: $panel-bg-primary;
  color: $text-color-primary;
}
.el-dialog{
  background-color: $panel-bg-primary!important;
}
.el-dialog__title{
  color: $text-color-primary!important;
}
.el-input-group__append, .el-input-group__prepend{
  background-color: $panel-bg-primary!important;
  color: $text-color-primary!important;
  border-color: $panel-border-primary!important;
}
.el-pagination__total,.el-pagination__jump{
  color: $text-color-primary!important;
}
.el-form-item__label{
  color: $text-color-white!important;
}
.el-tabs__item{
  color: white;
}
.el-tabs__nav-wrap::after{
  background-color: $panel-border-primary!important;
}
.el-input__inner,.el-textarea__inner{
  background-color: transparent!important;
  color: $text-color-white!important;
  border-color: $panel-border-primary!important;
}
.el-input.is-disabled .el-input__inner,.el-input.is-disabled .el-textarea__inner{
  background-color: rgba(255,255,255,0.1) !important;
}
.el-range-separator{
  color: $text-color-primary!important;
}
.el-checkbox{
  color: $text-color-white;
}
.el-radio,.el-radio__input.is-checked + .el-radio__label,.el-upload-list__item-name {
  color: $text-color-primary !important;
}
// .el-textarea__inner{
//   background-color: $panel-bg-primary !important;
//   color: #ffffff !important;
//   border:1px solid #535a77!important;
// }
.el-textarea .el-input__count {
  background: none !important;
  color: $text-color-primary !important;
}

.el-button--default ,.el-button--cancel{
  background: none !important;
  color: $text-color-primary !important;
  border: solid 1px #636d8e !important;
}

.el-button.is-circle{
  border: solid 1px transparent !important;
}

.el-radio-button__inner{
  background: none !important;
  color: $text-color-primary !important;
  border: 1px solid $text-color-primary !important;
}
.is-active .el-radio-button__inner{
  color: $fc-theme !important;
  border: 1px solid $fc-theme !important;
}

.el-message-box{
  background-color: $panel-bg-primary;
}
.el-message-box__content{
  color: $text-color-primary;
}
.el-button--default{
  background-color: $panel-bg-primary;
  color: $text-color-primary;

  &:hover,&:focus{
    background-color: $panel-bg-primary;
    color: $text-color-primary;
    box-shadow: none;
  }
}
.el-message-box__title{
  color: $text-color-primary;
}

.el-message-box{
  .el-button--default{
    background-color: inherit !important;
    color:inherit !important;
    &:hover,&:focus{
      background-color: inherit !important;
      color: inherit !important;
      box-shadow: none;
    }
  }
}


/*theme light 部分场景需要light*/

.theme-light{
  .el-input__inner{
    background-color: transparent!important;
    color: #333333 !important;
    border-color: $panel-bg-primary !important;
  }
  input {
    background-color: transparent !important;
    color: $panel-bg-primary !important;
  }
}


.el-loading-mask{
  background-color: rgba(0, 0, 0,0.6) !important;
}


.el-message-box{
  background-color: $panel-bg-primary!important;
  color: $text-color-primary!important;
  border: none !important;
  .el-message-box__title{
    color: $text-color-primary!important;
  }
  .el-message-box__content{
    color: #ffffff !important;
  }

  .el-button--default.el-button--primary {
    color: #FFFFFF !important;
    background-color: $fc-theme !important;
    border-color: $fc-theme !important;
  }
}


.el-tabs__item{
  color: $text-color-primary!important;
}

.el-tabs__item.is-active {
  color:  $fc-theme !important;
}


.el-table__footer-wrapper tbody td.el-table__cell {
  background: transparent !important;
}




//huyu hang
.el-message--success,.el-message--error {
  background-color: #ffffff !important;
  border: none !important;
  .el-message__content {
    color: #333333 !important;
  }
}
.popper__arrow{
  display: none !important;
}
.el-empty {
  opacity: 0.35 !important;
}

.el-tree-node:focus > .el-tree-node__content {
  background-color: rgba($color: #fff, $alpha: 0.1) !important;
}
.el-tree-node__content:hover {
  background-color: rgba($color: #fff, $alpha: 0.1) !important;
}
.primary-color,
.danger-color {
  // color:  $text-color-primary !important;
}
.el-tree {
  padding: 12px !important;
  height: 100% !important;
  background: none !important;
  .el-tree-node {
    .el-tree-node__children {
      overflow-x: auto !important;
      overflow-y: none !important;
      color: $text-color-primary !important;
    }
    .el-tree-node__children:hover {
      // background-color: #3f4b82 !important;
      color: #0288f9 !important;
    }
    .el-tree-node__content {
      color:  $text-color-primary  !important;
    }
    .my-tree-node {
      width: 100% !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }
    .el-button--mini.is-circle {
      padding: 5px !important;
    }
    .el-button + .el-button {
      margin: 0 !important;
    }
  }
}


.el-icon-close-tip{
  display: none !important;
}

.el-dialog__body{
  padding: 15px 20px !important;
}


.upload-demo{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .el-button{
    margin-top: 0 !important;
    margin-right: 15px !important;
  }
  .el-upload-list__item-name{
    margin-top: -10px !important;
    max-width: 150px !important;
  }
}

.el-table--group::after, .el-table--border::after, .el-table::before{
  background-color:transparent!important;
}

.upload-demo *{
  transition:none !important;
  animation: none !important;
}


//.el-input input::placeholder{
//  color:red;
//}
